//@font-face {
//    font-family: 'Heebo';
//    src: local("☺"),url('/assets/fonts/Heebo-VariableFont_wght.ttf') format('trueType');
//    font-weight: 1 999;
//}

@font-face{
    font-family: 'Myriad Pro';
    src: local("☺"), url("/assets/fonts/MYRIADPRO-LIGHT.otf") format("opentype");
    font-weight: 300;
    font-style: normal;
}

@font-face{
    font-family: 'Myriad Pro';
    src: local("☺"), url("/assets/fonts/MYRIADPRO-REGULAR.otf") format("opentype");
    font-weight: 400;
    font-style: normal;
}

@font-face{
    font-family: 'Myriad Pro';
    src: local("☺"), url("/assets/fonts/MYRIADPRO-SEMIBOLD.otf") format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face{
    font-family: 'Myriad Pro';
    src: local("☺"), url("/assets/fonts/MYRIADPRO-BOLD.otf") format("opentype");
    font-weight: 700;
    font-style: normal;
}