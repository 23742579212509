@use "_typography.scss";
@use "vendors/_xroot";

.parallelogram-5{
  mask-image: url('/assets/img/svg/icon_parallelogram-5.svg');
}


// list

ul{
  .list{
    padding-left: 15px;
    position: relative;
    &:before{
      position: absolute;
      left: 0;
      float: left;
      display: block;
      content: "•";
    }
  }
}

// carousel
.module-carousel {

  $slick-dot-color : #2F335E;
  $slick-opacity-default : 0.75 !default;

  .slick-prev,
  .slick-next {
    top: 50%;
    z-index: 10;

    &:before {
      font-size: 20px;
      color: $slick-dot-color;
    }
  }

  .slick-prev {
    left: -35px;

    @media screen and (max-width:768px) {
      left: 10px;
    }

    [dir="rtl"] & {
      left: auto;
      right: -35px;
    }

  }

  .slick-next {
    right: -35px;
    @media screen and (max-width:768px) {
      right: 10px;
    }

    [dir="rtl"] & {
      left: -35px;
      right: auto;
    }

  }


  /* Dots */


  .slick-dots {
    bottom: -12px;
    li {

      button {

        &:before {
          color: $slick-dot-color;
        }
      }

      &.slick-active button:before {
        color: $slick-dot-color;
        opacity: $slick-opacity-default;
      }
    }
  }
}



  @media screen and (max-width: 1400px) {
    .slick-slider{
      .slick-prev,
      .slick-next {
        top: 90%;
      }
    }
  }


